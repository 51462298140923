
import { TableColumns, UploadFileExtend } from "@/types/BasicComponent";
import { defineComponent, onMounted, reactive, ref } from "vue";
import BasicTable from '@/components/BasicPageLayout/BasicTable.vue'
import BasicImageList from '@/components/BasicPageLayout/BasicImageList.vue'
import { fileByXqjDeviceId, getXqjzDeviceInfo } from "@/api/xqjz";
import { getFileBasePreviewUrl } from "@/api/file";
import XqjzPosMap from "@/views/finance/XqjzPosMap.vue"
import { GEOPosition } from "@/types/Common";

export default defineComponent({
  components: {
    BasicTable,
    BasicImageList,
    XqjzPosMap
  },
  props: {
    xqjzId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const detailRef = ref()
    const xqjzPosMapRef = ref()
    const tableData = reactive<AnyArray>([])
    const imageLists = reactive<Partial<UploadFileExtend>[][]>([])

    onMounted(async () => {
      tableData.length = 0
      const resp = await getXqjzDeviceInfo(props.xqjzId)
      tableData.splice(0, 0, ...resp.data.data)
      imageLists.length = 0
      
      const baseImgUrlResp = await getFileBasePreviewUrl()
      tableData.forEach(row => {
        const images = ref<Partial<UploadFileExtend>[]>([])
        fileByXqjDeviceId(row.id).then(async resp => {
          
          const baseImgUrl = baseImgUrlResp.data.data
          const fileInfoList = resp.data.data as AnyArray
          fileInfoList.forEach(fileInfo => {
            if (fileInfo.fileguid) {
              const url =  baseImgUrl + fileInfo.fileguid
              images.value.push({ url })
            }
          })
        })
        imageLists.push(images.value)
      })
    })

    const onShowMap = (pos: GEOPosition) => {
      xqjzPosMapRef.value.show(pos)
    }

    const tableColumns = reactive<TableColumns>([
      {
        type: 'expand',
        render: scope => {
          const index = scope.$index
          const images = imageLists[index]
          
          return (
            <el-row style="width: 100%;" justify="center">
              {!images || images.length === 0
                ? <div style="color: #909399">暂无设备图片</div>
                : <BasicImageList
                    fileList={images}
                    size="medium"
                    disabled={true}
                  ></BasicImageList>
              }
            </el-row>
          )
        }
      }, {
        prop: 'index',
        type: 'index',
        label: '序号',
        width: '60px',
        align: 'center',
      }, {
        prop: 'diCode',
        label: '旋切机编号',
        minWidth: '180px',
      }, {
        prop: 'diType',
        label: '旋切机型号',
        minWidth: '120px',
      }, {
        prop: 'diNo',
        label: '旋切机设备号',
        minWidth: '120px'
      }, {
        prop: 'diAddress',
        label: '旋切机地址',
        minWidth: '200px'
      }, {
        prop: 'diPhone',
        label: '联系电话',
        minWidth: '120px'
      }, {
        prop: 'diLon',
        label: '经度',
        align: 'center',
        minWidth: '100px'
      }, {
        prop: 'diLat',
        label: '纬度',
        align: 'center',
        minWidth: '100px'
      }, {
        type: 'render',
        label: '位置',
        align: 'center',
        render: scope => {
          if (scope.row.diLon == 0 || scope.row.diLat == 0) return '暂无位置信息'
          const pos: GEOPosition = {
            lng: scope.row.diLon,
            lat: scope.row.diLat
          }
          return (
            <el-button
              type="primary"
              icon="el-icon-map-location"
              circle size="mini"
              onClick={() => onShowMap(pos)}
            ></el-button>
          )
        }
      }
    ])

    return {
      detailRef,
      tableColumns,
      tableData,
      xqjzPosMapRef
    }
  }
})
