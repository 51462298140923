import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form, {
      class: "dialog-form",
      model: "ruleForm",
      ref: "ruleForm",
      "label-width": "180px",
      inline: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          prop: "factoryNo",
          size: "small",
          label: "厂房编号"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请输入厂房编号",
              modelValue: _ctx.formData.factoryNo,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.factoryNo) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "leaseArea",
          size: "small",
          label: "租赁面积"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请输入租赁面积",
              modelValue: _ctx.formData.leaseArea,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.leaseArea) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "leasePrice",
          size: "small",
          label: "租赁单价"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请输入租赁单价",
              modelValue: _ctx.formData.leasePrice,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.leasePrice) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "villageName",
          size: "small",
          label: "所属园区"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_cascader, {
              modelValue: _ctx.cascaderValue,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.cascaderValue) = $event)),
              props: _ctx.cascaderProps,
              "show-all-levels": false,
              placeholder: _ctx.formData.villageName,
              class: _normalizeClass([_ctx.formData.villageName? 'cascader-placeholder-black': '']),
              style: {"width":"200px"}
            }, null, 8, ["modelValue", "props", "placeholder", "class"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "address",
          size: "small",
          label: "地址"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              style: {"width":"980px"},
              placeholder: "请输入地址",
              type: "textarea",
              modelValue: _ctx.formData.address,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.address) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512),
    _createVNode(_component_el_row, {
      style: {"width":"100%","margin-top":"40px"},
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "primary",
          size: "small",
          onClick: _ctx.onSave
        }, {
          default: _withCtx(() => [
            _createTextVNode("保存修改")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ]))
}