
import { Buttons, FormItems, UploadFileExtend } from "@/types/BasicComponent";
import { defineComponent, reactive, ref, toRef } from "vue";
import BasicFormDialog from '@/components/BasicPageLayout/BasicFormDialog.vue'
import BasicImageList from '@/components/BasicPageLayout/BasicImageList.vue'
import { clearObject, copyObject, partialCopyObject } from "@/utils/common";
import { ElMessage } from "element-plus";
import { getBusinessLicenseImages, updateRegistration } from "@/api/xqjz";
import { bindUploadedFile, deleteBindUploadedFile, getFileBasePreviewUrl, uploadFile } from "@/api/file";
import { FormRulesMap } from "element-plus/lib/components/form/src/form.type";
import { FileType, SourceType } from "@/types/Common";

export default defineComponent({
  components: {
    BasicFormDialog,
    BasicImageList
  },
  emits: ['on-success'],
  setup(props, { emit }) {

    const modalShow = ref(false)
    const formData = reactive<AnyObject>({})

    const closeShouldEmit = ref(false)

    const fileList = reactive<Partial<UploadFileExtend>[]>([])

    const show = async (initFormData: AnyObject) => {
      clearObject(formData)
      copyObject(formData, initFormData)
      closeShouldEmit.value = false
      fileList.length = 0
      if (formData.bid > 0) {
        const baseImgUrlResp = await getFileBasePreviewUrl()
        const businessLicenseImageResp = await getBusinessLicenseImages(formData.bid)
        const baseImgUrl = baseImgUrlResp.data.data
        const fileInfoList = businessLicenseImageResp.data.data as AnyArray
        
        fileInfoList.forEach(fileInfo => {
          const url =  fileInfo.fileguid? baseImgUrl + fileInfo.fileguid: baseImgUrl.replace('userFileGuid', 'userFileId') + fileInfo.fileid
          fileList.push({ url, title: '营业执照', id: fileInfo.fileid })
        })
      }

      modalShow.value = true
    }

    const onFileAdd = async (file: UploadFileExtend) => {
      // 数据库里面没有记录，先新建一个空的
      if (formData.bid == null || formData.bid === 0) {
        const resp = await updateRegistration({
          bid: 0,
          type: 2,
          associateId: formData.contractid,
          status: 10 // 新建
        })
        formData.bid = resp.data.data
        closeShouldEmit.value = true
      }
      const uploadResp = await uploadFile(file.raw, '/BusinessLicense/')
      const fileInfo = uploadResp.data.data.upFileList[0]
      await bindUploadedFile({
        sourceId: formData.bid,
        sourceTypeId: SourceType.BUSINESS_LICENSE,
        fileTypeId: FileType.BUSINESS_LICENSE,
        fileGuid: fileInfo.userFileGuid,
        fileId: fileInfo.fileId
      })
    }
    const onFileDelete = (file: AnyObject) => {
      return new Promise<void>((resolve, reject) => {
        deleteBindUploadedFile([file.id])
        resolve()
      })
    }
    const closeAndEmit = () => {
      if (closeShouldEmit.value) {
        emit('on-success')
      }
      modalShow.value = false
    }

    const formItems = reactive<FormItems>([
      {
        label: '个体工商户名称',
        type: 'input',
        model: toRef(formData, 'individualName'),
        prop: 'individualName',
        clearable: false,
      }, {
        label: '统一信用代码',
        type: 'input',
        model: toRef(formData, 'licenseNo'),
        prop: 'licenseNo',
      }, {
        label: '营业执照注册日期',
        type: 'date',
        model: toRef(formData, 'licenseTime'),
        prop: 'licenseTime',
      }, {
        label: '营业执照到期日期',
        type: 'date',
        model: toRef(formData, 'expireTime'),
        prop: 'expireTime',
      }
    ])

    // 营业执照信息校验
    const formRules = reactive<FormRulesMap>({
      individualName: [
        { required: true, message: '请输入个体工商户名称'}
      ],
      licenseNo: [
        { required: true, message: '请输入统一信用代码'}
      ],
      licenseTime: [
        { required: true, message: '请选择营业执照注册时间'},
      ],
      expireTime: [
        { required: true, message: '请选择营业执照到期日期'},
      ]
    })

    const formRef = ref()
    const footerButtons = reactive<Buttons>([
      {
        label: '通过审核',
        onClick: async () => {
          await formRef.value.validate()
          const requestData: AnyObject = {}
          partialCopyObject(requestData, formData, 'bid', 'associateType', 'associateId', 'expTime', 'individualName', 'licenseNo', 'licenseTime')
          requestData.associateType = 2  // 子账号
          requestData.associateId = formData.contractid
          requestData.status = 30 // 有效
          await updateRegistration(requestData)
          ElMessage.success?.('已通过审核')
          modalShow.value = false
          closeShouldEmit.value = true
        }
      }, {
        label: '冻结',
        type: 'danger',
        onClick: async () => {
          await formRef.value.validate()
          const requestData: AnyObject = {}
          partialCopyObject(requestData, formData, 'bid', 'associateType', 'associateId', 'expTime', 'individualName', 'licenseNo', 'licenseTime')
          requestData.associateType = 2  // 子账号
          requestData.associateId = formData.contractid
          requestData.status = 40 // 冻结
          await updateRegistration(requestData)
          ElMessage.success?.('冻结成功')
          modalShow.value = false
          closeShouldEmit.value = true
        }
      }, {
        label: '关闭',
        type: 'default',
        onClick: () => {
          modalShow.value = false
        }
      }
    ])

    return {
      modalShow,
      formItems,
      footerButtons,
      fileList,
      formRules,
      formRef,
      closeAndEmit,
      emit,
      show,
      onFileAdd,
      onFileDelete
    }
  }
})
