import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicTable = _resolveComponent("BasicTable")!
  const _component_XQJZRegistrationDetail = _resolveComponent("XQJZRegistrationDetail")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BasicTable, {
      tableColumns: _ctx.tableColumns,
      tableData: _ctx.tableData
    }, null, 8, ["tableColumns", "tableData"]),
    _createVNode(_component_XQJZRegistrationDetail, { ref: "detailRef" }, null, 512)
  ]))
}