
import { fileByXqjzid } from '@/api/xqjz'
import { reactive, ref, defineComponent, PropType, onMounted } from 'vue'
import BasicImageList from '@/components/BasicPageLayout/BasicImageList.vue'
import { getFileBasePreviewUrl } from '@/api/file'
import { FileType, fileTypeNameMap } from '@/types/Common'
export default defineComponent({
  components: {
    BasicImageList
  },
  props: {
    formData: {
      type: Object as PropType<AnyObject>,
      default: {
        name: '',
        phone: '',
        idCard: '',
        machineCount: 0
      }
    },
    xqjzId: {
      type: Number,
      default: -1
    }
  },
  setup(props) {
    const formRef = ref()

    const images = reactive<AnyArray>([])
    onMounted(async () => {
      const baseImgUrlResp = await getFileBasePreviewUrl()
      const fileResp = await fileByXqjzid(props.xqjzId)
      const fileInfo = fileResp.data.data
      const baseImgUrl = baseImgUrlResp.data.data
      const fileInfoList = fileResp.data.data as AnyArray
      fileInfoList.forEach(fileInfo => {
        const url =  fileInfo.fileguid? baseImgUrl + fileInfo.fileguid: baseImgUrl.replace('userFileGuid', 'userFileId') + fileInfo.fileid
        let title = fileTypeNameMap[fileInfo.filetypeid as FileType] || ''
        images.push({ url, title })
      })
    })

    return {
      images,
      formRef
    }
  }

})
