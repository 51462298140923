
import { defineComponent, ref } from "vue";
import BasicFormDialog from '@/components/BasicPageLayout/BasicFormDialog.vue'
import { GEOPosition } from '@/types/Common'

export default defineComponent({
  components: {
    BasicFormDialog
  },
  setup(props) {

    const modalShow = ref(false)
    const position = ref<GEOPosition>({ lng: 116.404, lat: 39.915 })

    const show = (pos: GEOPosition) => {
      modalShow.value = true
      position.value = pos
    }

    const renderMap = () => {
      const bMap = new BMapGL.Map('xqjz-pos-map-container')
      const point = new BMapGL.Point(position.value.lng, position.value.lat);
      bMap.centerAndZoom(point, 15);
      const marker = new BMapGL.Marker(point);  // 创建标注    
      bMap.addOverlay(marker);                // 将标注添加到地图中 
    }

    return {
      modalShow,
      show,
      renderMap
    }
  }
})
