import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","margin-top":"25px","display":"flex","justify-content":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicImageList = _resolveComponent("BasicImageList")!
  const _component_BasicFormDialog = _resolveComponent("BasicFormDialog")!

  return (_openBlock(), _createBlock(_component_BasicFormDialog, {
    modelValue: _ctx.modalShow,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalShow) = $event)),
    title: "登记信息详情",
    footerButtons: _ctx.footerButtons,
    formItems: _ctx.formItems,
    width: "1300px",
    closeOnClickModal: true,
    onClose: _ctx.closeAndEmit,
    ref: "formRef",
    formRules: _ctx.formRules
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BasicImageList, {
          fileList: _ctx.fileList,
          size: "large",
          onFileAdd: _ctx.onFileAdd,
          onFileDelete: _ctx.onFileDelete
        }, {
          triggerText: _withCtx(() => [
            _createTextVNode(" 上传营业执照 ")
          ]),
          _: 1
        }, 8, ["fileList", "onFileAdd", "onFileDelete"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "footerButtons", "formItems", "onClose", "formRules"]))
}