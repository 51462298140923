import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form, {
      class: "dialog-form",
      model: _ctx.formData,
      ref: "formRef",
      "label-width": "180px",
      inline: true,
      rules: _ctx.formRules
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          prop: "electricmonth",
          size: "small",
          label: "本月电费限制数"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请输入数据",
              modelValue: _ctx.formData.electricmonth,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.electricmonth) = $event)),
              style: {"width":"200px"}
            }, {
              suffix: _withCtx(() => [
                _createTextVNode("度")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "eprice",
          size: "small",
          label: "电费单价"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请输入数据",
              modelValue: _ctx.formData.eprice,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.eprice) = $event)),
              style: {"width":"200px"}
            }, {
              suffix: _withCtx(() => [
                _createTextVNode("元")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "equippct",
          size: "small",
          label: "设备租金占比"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请输入数据",
              modelValue: _ctx.formData.equippct,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.equippct) = $event)),
              style: {"width":"200px"}
            }, {
              suffix: _withCtx(() => [
                _createTextVNode("%")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "logtoboard",
          size: "small",
          label: "原木单板转换比例"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请输入数据",
              modelValue: _ctx.formData.logtoboard,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.logtoboard) = $event)),
              style: {"width":"200px"}
            }, {
              suffix: _withCtx(() => [
                _createTextVNode("%")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "spacepct",
          size: "small",
          label: "场地费占比"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请输入数据",
              modelValue: _ctx.formData.spacepct,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.spacepct) = $event)),
              style: {"width":"200px"}
            }, {
              suffix: _withCtx(() => [
                _createTextVNode("%")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "topLimit",
          size: "small",
          label: "每月生产单板总上限"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请输入数据",
              modelValue: _ctx.formData.topLimit,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.topLimit) = $event)),
              style: {"width":"200px"}
            }, {
              suffix: _withCtx(() => [
                _createTextVNode("方")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "transferlinit",
          size: "small",
          label: "每个账号每月转账限额"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请输入数据",
              modelValue: _ctx.formData.transferlinit,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.transferlinit) = $event)),
              style: {"width":"200px"}
            }, {
              suffix: _withCtx(() => [
                _createTextVNode("元")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "workpct",
          size: "small",
          label: "联合经营费用占比"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请输入数据",
              modelValue: _ctx.formData.workpct,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.workpct) = $event)),
              style: {"width":"200px"}
            }, {
              suffix: _withCtx(() => [
                _createTextVNode("%")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_el_row, {
      style: {"width":"100%","margin-top":"20px"},
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "primary",
          size: "small",
          onClick: _ctx.onSave
        }, {
          default: _withCtx(() => [
            _createTextVNode("保存修改")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ]))
}