import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_BasicImageList = _resolveComponent("BasicImageList")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form, {
      class: "dialog-form",
      model: _ctx.formData,
      ref: "formRef",
      "label-width": "180px",
      inline: true,
      rules: _ctx.formRules
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          prop: "individualName",
          size: "small",
          label: "个体工商户名称"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请输入个体工商户名称",
              modelValue: _ctx.formData.individualName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.individualName) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "licenseNo",
          size: "small",
          label: "统一信用代码"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请输入统一信用代码",
              modelValue: _ctx.formData.licenseNo,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.licenseNo) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "licenseTime",
          size: "small",
          label: "营业执照注册日期"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_date_picker, {
              placeholder: "营业执照注册日期",
              modelValue: _ctx.formData.licenseTime,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.licenseTime) = $event)),
              clearable: false,
              style: {"width":"200px"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "expTime",
          size: "small",
          label: "营业执照到期日期"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_date_picker, {
              placeholder: "营业执照到期日期",
              modelValue: _ctx.formData.expTime,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.expTime) = $event)),
              clearable: false,
              style: {"width":"200px"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_el_row, {
      style: {"width":"100%","margin-top":"40px"},
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BasicImageList, {
          fileList: _ctx.images,
          size: "large",
          onFileAdd: _ctx.onFileAdd,
          onFileDelete: _ctx.onFileDelete
        }, {
          triggerText: _withCtx(() => [
            _createTextVNode(" 上传营业执照 ")
          ]),
          _: 1
        }, 8, ["fileList", "onFileAdd", "onFileDelete"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, {
      style: {"width":"100%","margin-top":"40px"},
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "primary",
          size: "small",
          onClick: _ctx.onSave
        }, {
          default: _withCtx(() => [
            _createTextVNode("保存修改")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ]))
}