
import { getAreaParksByAreaCode, getAreaListByPacode, updateXqjzInfoExif } from '@/api/xqjz'
import { ElMessage } from 'element-plus'
import { reactive, ref, defineComponent, PropType, watch } from 'vue'

export default defineComponent({
  props: {
    formData: {
      type: Object as PropType<AnyObject>,
      default: {
        address: '',
        factoryNo: '',
        leaseArea: undefined,
        leasePrice: undefined,
        villageName: '',
        apId: -1,
        xqjid: 0
      }
    },
	xqjzId:{
		type: Number,
		default: 0
	}
  },
  setup(props) {
    const formRef = ref()

    // 园区级联选择
    const cascaderValue = ref([])
    const cascaderProps = reactive({
      lazy: true,
      async lazyLoad(node: any, resolve: any) {
        const { level, value } = node
        let paCode: string
        if (level === 0) paCode = "450000"
        else paCode = value

        if (level < 3) {
          const resp = await getAreaListByPacode(paCode)
          const respData = resp.data.data
          const cascaderData = respData.map((areaInfo: AnyObject) => {
            return {
              label: areaInfo.areaName,
              value: areaInfo.areaCode,
            }
          })
          resolve(cascaderData)
        } else {
          const resp = await getAreaParksByAreaCode(paCode)
          const respData = resp.data.data
          const cascaderData = respData.map((parkInfo: AnyObject) => {
            return {
              label: parkInfo.apName,
              value: parkInfo.apId,
              leaf: true
            }
          })
          resolve(cascaderData)
        }
      }
    })
    watch(cascaderValue, val => {
      props.formData.apId = val[3]
    })

    // 修改场地信息
    const onSave = async () => {
	  props.formData.xqjid = props.xqjzId;
      await updateXqjzInfoExif(props.formData)
      ElMessage.success?.('修改场地信息成功')
    }

    return {
      onSave,
      formRef,
      cascaderProps,
      cascaderValue
    }
  }

})
