
import { TableColumns } from "@/types/BasicComponent";
import { formatDate, formatTime } from "@/utils/common";
import { defineComponent, reactive, ref } from "vue";
import BasicTable from '@/components/BasicPageLayout/BasicTable.vue'
import XQJZRegistrationDetail from '@/views/xqjz/XQJZRegistrationDetail.vue'

export default defineComponent({
  components: {
    XQJZRegistrationDetail,
    BasicTable
  },
  props: {
    tableData: {
      type: Array
    },
    xqjzId: {
      type: Number
    }
  },
  setup() {
    /** 登记信息 */
    const detailRef = ref()
    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        type: 'index',
        label: '序号',
        width: '60px',
        align: 'center',
      }, {
        prop: 'name',
        label: '签约人',
        minWidth: '150px',
      }, {
        prop: 'idCard',
        label: '身份证号',
        minWidth: '200px'
      }, {
        prop: 'phone',
        label: '电话号码',
        minWidth: '150px'
      }, {
        type: 'render',
        prop: 'registerTime',
        label: '注册时间',
        minWidth: '180px',
        render: scope => formatTime(scope.row.registerTime)
      }, {
        prop: 'cardnumber',
        label: '银行卡号',
        minWidth: '240px',
        align: 'left',
      }, {
        prop: 'bankname',
        label: '银行名称',
        minWidth: '150px',
        align: 'left'
      }, {
        prop: 'bankcode',
        label: '银行代码',
        minWidth: '150px',
        align: 'left',
      }, {
        type: 'render',
        prop: 'createdtime',
        label: '银行卡绑定时间',
        minWidth: '180px',
        align: 'center',
        render: scope => formatTime(scope.row.createdtime)
      }, {
        prop: 'individualName',
        label: '个体工商户名称',
        minWidth: '150px',
        align: 'center'
      }, {
        prop: 'licenseNo',
        label: '统一信用代码',
        minWidth: '150px',
        align: 'center'
      }, {
        type: 'render',
        prop: 'licenseTime',
        label: '营业执照注册日期',
        minWidth: '150px',
        align: 'center',
        render: scope => formatDate(scope.row.licenseTime)
      }, {
        type: 'render',
        prop: 'expireTime',
        label: '营业执照到期日期',
        minWidth: '150px',
        align: 'center',
        render: scope => formatDate(scope.row.expireTime)
      }, {
        prop: 'businessLicenseStatus',
        type: 'status',
        label: '营业执照审核状态',
        minWidth: '150px',
        align: 'center',
        statusType: [
          { value: 0, label: '未录入', type: 'info' },
          { value: 10, label: '新建', type: 'primary' },
          { value: 20, label: '待审核', type: 'warning' },
          { value: 30, label: '已生效', type: 'success' },
          { value: 40, label: '冻结', type: 'danger' },
        ]
      }, {
        type: 'button',
        label: '操作',
        width: '100px',
        fixed: 'right',
        buttons: [
          {
            label: '编辑',
            onClick: scope => detailRef.value.show(scope.row)
          }
        ]
      }
    ])

    return {
      detailRef,
      tableColumns
    }
  }
})
