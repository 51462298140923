
import BasicFormDialog from "@/components/BasicPageLayout/BasicFormDialog.vue";
import BasicInputGroup from '@/components/BasicPageLayout/BasicInputGroup.vue'
import BasicImageList from '@/components/BasicPageLayout/BasicImageList.vue'
import BasicTable from '@/components/BasicPageLayout/BasicTable.vue'
import XQJZRegistrationDetail from '@/views/xqjz/XQJZRegistrationDetail.vue'
// import AddDevice from '@/views/xqjz/AddDevice.vue'
import { UploadFileExtend } from "@/types/BasicComponent";
import { defineComponent, reactive, ref } from "vue";
import { getXqjzBankCardInfo, getXqjzBaseInfo, getXqjzBusinessLicenseInfo, getXqjzConfigInfo, getXqjzDeviceInfo, getXqjzExifInfo } from "@/api/xqjz";
import useRole from "@/utils/composables/useRole";
import { UserRole } from "@/types/Role";
import { clearObject, copyObject } from "@/utils/common";
import XQJZAccount from '@/views/xqjz/XQJZDetailTabs/XQJZAccount.vue'
import XQJZArea from '@/views/xqjz/XQJZDetailTabs/XQJZArea.vue'
import XQJZFinance from '@/views/xqjz/XQJZDetailTabs/XQJZFinance.vue'
import XQJZBusinessLicense from '@/views/xqjz/XQJZDetailTabs/XQJZBusinessLIcense.vue'
import XQJZRegistration from '@/views/xqjz/XQJZDetailTabs/XQJZRegistration.vue'
import XQJZDevice from '@/views/xqjz/XQJZDetailTabs/XQJZDevice.vue'

export default defineComponent({
  components: { 
    BasicFormDialog,
    BasicInputGroup,
    BasicImageList,
    BasicTable,
    // AddDevice,
    XQJZRegistrationDetail,
    XQJZAccount,
    XQJZArea,
    XQJZFinance,
    XQJZBusinessLicense,
    XQJZRegistration,
    XQJZDevice
  },
  emits: ['on-success'],
  setup(props, { emit }) {
    const modalShow = ref(false)

    const roleUtil = useRole()

    const activeTab = ref('1')

    // 基本数据
    const baseInputData = reactive<AnyObject>({})
    // 设备数据
    const deviceDataList = reactive<AnyObject[]>([])
    // 场地数据
    const exifInputData = reactive<AnyObject>({})
    // 营业资格证
    const businessLicenseInputData = reactive<AnyObject>({})
    // 营业资格证图片
    const businessLicenseImages = reactive<Partial<UploadFileExtend>[]>([])
    // 财务数据
    const financeInputData = reactive<AnyObject>({})
    // 登记数据列表
    const registrationTableData = reactive<AnyArray>([])
    // 旋切机主Id
    const xqjzId = ref(-1)

    const show = async (id: number) => {
      // 根据角色查询旋切机主详情
      xqjzId.value = id
      
      const [xqjzBaseInfo, xqjzExifInfo, xqjzConfigInfo, xqjzBusinessLicenseInfo, xqjzBankCardInfo] = 
        await Promise.all([getXqjzBaseInfo(id), getXqjzExifInfo(id), getXqjzConfigInfo(id), getXqjzBusinessLicenseInfo(id), getXqjzBankCardInfo(id)])

      const baseData = xqjzBaseInfo.data.data as AnyObject
      const financeData = xqjzConfigInfo.data.data as AnyObject
      const exifData = xqjzExifInfo.data.data as AnyObject
      const businessLicenseData = xqjzBusinessLicenseInfo.data.data as AnyObject

      // 旋切机设备数据
      deviceDataList.length = 0
      deviceDataList.splice(0, 0, [])
      // 登记列表数据处理
      const registrationInfoList = xqjzBankCardInfo.data.data
      registrationTableData.length = 0

      for (let registrationInfo of registrationInfoList) {
        registrationTableData.push(registrationInfo)
      }

      clearObject(baseInputData)
      clearObject(financeInputData)
      clearObject(exifInputData)
      clearObject(businessLicenseInputData)
      if (baseData) copyObject(baseInputData, baseData)
      if (financeData) copyObject(financeInputData, financeData)
      if (exifData) copyObject(exifInputData, exifData)
      if (businessLicenseData) copyObject(businessLicenseInputData, businessLicenseData)

      modalShow.value = true
    }

    const onClose = () => {
      emit('on-success')
    }

    const xqjcDialogRef = ref()

    return {
      show,
      onClose,
      xqjzId,
      deviceDataList,
      activeTab,
      modalShow,
      xqjcDialogRef,
      roleUtil,
      UserRole,
      baseInputData,
      financeInputData,
      exifInputData,
      businessLicenseInputData,
      registrationTableData
    }
  }
})
