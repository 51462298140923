import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1cf77d54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"min-height":"400px","width":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XQJZAccount = _resolveComponent("XQJZAccount")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_XQJZDevice = _resolveComponent("XQJZDevice")!
  const _component_XQJZArea = _resolveComponent("XQJZArea")!
  const _component_XQJZFinance = _resolveComponent("XQJZFinance")!
  const _component_XQJZBusinessLicense = _resolveComponent("XQJZBusinessLicense")!
  const _component_XQJZRegistration = _resolveComponent("XQJZRegistration")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_BasicFormDialog = _resolveComponent("BasicFormDialog")!

  return (_openBlock(), _createBlock(_component_BasicFormDialog, {
    modelValue: _ctx.modalShow,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalShow) = $event)),
    title: "生产机主详情",
    ref: "xqjcDialogRef",
    width: "1400px",
    top: "7vh",
    closeOnClickModal: true,
    onClose: _ctx.onClose
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_tabs, {
          modelValue: _ctx.activeTab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
          type: "card",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: "账号信息",
              name: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_XQJZAccount, {
                  formData: _ctx.baseInputData,
                  xqjzId: _ctx.xqjzId
                }, null, 8, ["formData", "xqjzId"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, {
              label: "设备信息",
              name: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_XQJZDevice, { xqjzId: _ctx.xqjzId }, null, 8, ["xqjzId"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, {
              label: "场地信息",
              name: "3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_XQJZArea, {
                  formData: _ctx.exifInputData,
                  xqjzId: _ctx.xqjzId
                }, null, 8, ["formData", "xqjzId"])
              ]),
              _: 1
            }),
            (_ctx.roleUtil.hasRole(_ctx.UserRole.Financial))
              ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: 0,
                  label: "财务信息",
                  name: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_XQJZFinance, {
                      formData: _ctx.financeInputData,
                      xqjzId: _ctx.xqjzId
                    }, null, 8, ["formData", "xqjzId"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.roleUtil.hasRole(_ctx.UserRole.Financial))
              ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: 1,
                  label: "营业执照信息",
                  name: "5"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_XQJZBusinessLicense, {
                      formData: _ctx.businessLicenseInputData,
                      xqjzId: _ctx.xqjzId
                    }, null, 8, ["formData", "xqjzId"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.roleUtil.hasRole(_ctx.UserRole.Financial))
              ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: 2,
                  label: "登记信息",
                  name: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_XQJZRegistration, { tableData: _ctx.registrationTableData }, null, 8, ["tableData"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "onClose"]))
}