
import { reactive, ref, defineComponent, PropType } from 'vue'
import BasicImageList from '@/components/BasicPageLayout/BasicImageList.vue'
import { UploadFileExtend } from '@/types/BasicComponent'
import { getBusinessLicenseImages, updateRegistration } from '@/api/xqjz'
import { ElMessage } from 'element-plus'
import { bindUploadedFile, deleteBindUploadedFile, getFileBasePreviewUrl, uploadFile } from '@/api/file'
import { copyObject, isEmptyObject } from '@/utils/common'
import { FormRulesMap } from 'element-plus/lib/components/form/src/form.type'
import { FileType, SourceType } from '@/types/Common'

export default defineComponent({
  components: {
    BasicImageList
  },
  props: {
    formData: {
      type: Object as PropType<AnyObject>,
      default: {}
    },
    xqjzId: {
      type: Number
    }
  },
  setup(props, { emit }) {
    const formRef = ref()
    // 营业执照信息校验
    const formRules = reactive<FormRulesMap>({
      individualName: [
        { required: true, message: '请输入个体工商户名称'}
      ],
      licenseNo: [
        { required: true, message: '请输入统一信用代码'}
      ],
      licenseTime: [
        { required: true, message: '请选择营业执照注册时间'},
      ],
      expTime: [
        { required: true, message: '请选择营业执照到期日期'},
      ]
    })
    // 修改营业执照信息
    const onSave = async () => {
      await formRef.value.validate()
      const registrationRequestData: AnyObject = {}
      copyObject(registrationRequestData, props.formData)
      registrationRequestData.associateType = 1 // 主账号
      registrationRequestData.status = 30 // 直接通过
      registrationRequestData.associateId = props.xqjzId
      await updateRegistration(registrationRequestData)
      ElMessage.success?.('修改营业执照信息成功')
    }

    // 获取营业资格证图片
    const queryImages = async () => {
      images.length = 0
      if (!isEmptyObject(props.formData)) {
        const baseImgUrlResp = await getFileBasePreviewUrl()
        const businessLicenseImageResp = await getBusinessLicenseImages(props.formData.bid)
        const baseImgUrl = baseImgUrlResp.data.data
        const fileInfoList = businessLicenseImageResp.data.data as AnyArray
        fileInfoList.forEach(fileInfo => {
          const url =  fileInfo.fileguid? baseImgUrl + fileInfo.fileguid: baseImgUrl.replace('userFileGuid', 'userFileId') + fileInfo.fileid
          images.push({ url, id: fileInfo.fileid })
        })
      }
    }

    // 营业资格证图片
    const images = reactive<Partial<UploadFileExtend>[]>([])
    // 添加资格证图片
    const onFileAdd = async (file: UploadFileExtend) => {
      // 数据库里面没有记录，先新建一个空的
      if (props.formData.bid == null || props.formData.bid === 0) {
        const resp = await updateRegistration({
          associateType: 1, // 主账号
          associateId: props.xqjzId,
          bid: 0,
          status: 10 // 新建
        })
        if (resp.data.data === -1) {
          ElMessage.error?.('新建错误营业执照信息失败')
          throw new Error()
        }
        props.formData.bid = resp.data.data
      }
      const uploadResp = await uploadFile(file.raw, '/BusinessLicense/')
      const fileInfo = uploadResp.data.data.upFileList[0]
      await bindUploadedFile({
        sourceId: props.formData.bid,
        sourceTypeId: SourceType.BUSINESS_LICENSE,
        fileTypeId: FileType.BUSINESS_LICENSE,
        fileGuid: fileInfo.userFileGuid,
        fileId: fileInfo.fileId
      })
    }

    const onFileDelete = (file: AnyObject) => {
      return new Promise<void>((resolve, reject) => {
        deleteBindUploadedFile([file.id])
        resolve()
      })
    }

    queryImages()

    return {
      formRef,
      formRules,
      images,
      queryImages,
      onFileAdd,
      onSave,
      onFileDelete
    }
  }

})
