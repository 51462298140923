
import { updateXqjzInfoFinance } from '@/api/xqjz'
import { ElMessage } from 'element-plus'
import { reactive, ref, defineComponent, PropType } from 'vue'
import commonValidate from '@/utils/validate'
import { FormRulesMap } from 'element-plus/lib/components/form/src/form.type'

export default defineComponent({
  props: {
    formData: {
      type: Object as PropType<AnyObject>,
      default: {}
    },
    xqjzId: {
      type: Number,
      default: -1
    }
  },
  setup(props, { emit }) {
    const formRef = ref()

    const onSave = async () => {
      await formRef.value.validate()
      await updateXqjzInfoFinance({ xqjid: props.xqjzId, ...props.formData })
      ElMessage.success?.('修改成功')
      emit('on-success')
    }

    /** 财务信息校验 */
    const formRules = reactive<FormRulesMap>({
      electricmonth: [
        { required: true, message: '请输入本月电费限制数' },
        { type: 'number', validator: (rules, value) => commonValidate.amount(value), message: '请输入正确的本月电费限制数' }
      ],
      eprice: [
        { required: true, message: '请输入电费单价' },
        { type: 'number', validator: (rules, value) => commonValidate.amount(value), message: '请输入正确的电费单价' }
      ],
      equippct: [
        { required: true, message: '请输入设备租金占比' },
        { type: 'number', validator: (rules, value) => commonValidate.range(value, [0, 100]), message: '请输入正确的设备租金占比' }
      ],
      logtoboard: [
        { required: true, message: '请输入原木和单板转换比例' },
        { type: 'number', validator: (rules, value) => commonValidate.range(value, [0, 100]), message: '请输入正确的原木和单板转换比例' }
      ],
      spacepct: [
        { required: true, message: '请输入场地费占比' },
        { type: 'number', validator: (rules, value) => commonValidate.range(value, [0, 100]), message: '请输入正确的场地费占比' }
      ],
      topLimit: [
        { required: true, message: '请输入每月生产单板总上限' },
        { type: 'number', validator: (rules, value) => value >= 0, message: '请输入正确的每月生产单板总上限' }
      ],
      transferlinit: [
        { required: true, message: '请输入每个账号每月转账限额' },
        { type: 'number', validator: (rules, value) => value >= 0, message: '请输入正确的每个账号每月转账限额' }
      ],
      workpct: [
        { required: true, message: '请输入联合经营费用占比' },
        { type: 'number', validator: (rules, value) => commonValidate.range(value, [0, 100]), message: '请输入正确的联合经营费用占比' }
      ]
    })

    return {
      formRef,
      formRules,
      onSave
    }
  }

})
